<template>
  <section class="all-documents-view" v-loading="loading">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <div class="inner-navbar mb-1">
          <el-row>
            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <el-button size="mini" v-if="checkUrlType() != 'public'" @click="goBack"><i class="el-icon-back"></i></el-button>
                <span style="margin-left: 10px; font-size: large; font-weight: 500;">{{entity_name}}'s Dashboards</span>
            </el-col>
            <!-- <el-col :lg="12" :md="12" :sm="24" :xs="24">
              <div class="left-block">
                <div class="icon-block">
                  <div class="icon">
                    <img
                      src="@/assets/img/icons/menu/work-flow.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div class="icon-text">
                    <h3 class="table-head-text">
                      {{ "All Custom Dashboards" }}
                    </h3>
                  </div>
                </div>
              </div>
            </el-col> -->
            <el-col
              style="text-align: right"
              class="top-buttons-wrapper"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
            >
              <div class="right-block">
                <!-- <el-input
                  placeholder="Search"
                  v-model="search_string"
                  @input="loadData"
                  class="search_input"
                  :style="getIsMobile ? 'width:130px' : 'width:170px;'"
                ></el-input> -->
                <el-button type="primary" icon="el-icon-plus"  v-if="checkUrlType() != 'public'" @click="createNewDashboard"
        >Create New Dashboard</el-button
      >
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          v-loading="loading"
          class="vue-data-table-default"
          element-loading-text="Loading..."
        >
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            style="width: 100%"
            :element-loading-text="loadingText"
          >
            <el-table-column
              label="Name"
              id="name"
              prop="name"
              width="350"
              fixed="left"
            >
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.name">
                  <span style="color: #4c4b4e; font-weight: 400">{{
                    scope.row.name | truncate(25, "...")
                  }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="action" min-width="200" label="Actions">
              <template slot-scope="scope">
                <ul class="action-buttons hover-buttons">
                  <li>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        Actions
                        <i class="el-icon-caret-bottom el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          v-if="!getIsMobile"
                          @click="onEdit(scope.row)"
                          style="border: none"
                        >
                          <el-dropdown-item
                            icon="el-icon-edit-outline"
                            command="edit"
                            >Edit</el-dropdown-item
                          >
                        </a>
                        <a @click="onDelete(scope.row)" style="border: none">
                          <el-dropdown-item
                            icon="el-icon-delete"
                            command="delete"
                            >Delete</el-dropdown-item
                          >
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
            <el-table-column label="Main Dashboard" min-width="200">
              <template slot-scope="scope">
                <div>
                  <el-switch active-text="Show" inactive-text="Hide" v-model="scope.row.main_dashboard_visible" :disabled="disableSwitch(scope.row.main_dashboard_visible, scope.$index)" @change="updateDashboard(scope.row)">
                  </el-switch>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Last modified" width="200">
              <template slot-scope="scope" v-if="scope.row.updated_at">
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.updated_at | moment("MM-DD-YYYY") }}
                </p>
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.updated_at | moment("HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Created at" width="200">
              <template slot-scope="scope" v-if="scope.row.created_at">
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.created_at | moment("MM-DD-YYYY") }}
                </p>
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.created_at | moment("HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
export default {
  name: "ApplicationUserDashboards",
  data() {
    return {
      loading: false,
      configureLoading: false,
      data: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      search_string: "",
      entity_id: "",
      loadingText : 'Fetching Dashboards...',
      entity_name : '',
    };
  },
  mixins: [
    
    NavigationHelper,
],
  computed: {
    ...mapGetters("customDashboard", [
      "getUserDashboardsList",
      "getEntityUserDashboardsList",
      "getDeleteDashboardStatus"
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("sso",["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    this.entity_id = this.$route.params.entityId;
    this.entity_name = this.$route.query.name
    await this.getNecessaryInfo();
    await this.checkUrlType()
  },
  methods: {
    async getNecessaryInfo() {
      this.configureLoading = true
      this.loadingText = 'Fetching Dashboards...'
      let entityId = this.$route.params.entityId;
      await this.$store.dispatch(
        "customDashboard/fetchEntityUserDashboardsList",
        entityId
      );
      if (
        this.getEntityUserDashboardsList &&
        this.getEntityUserDashboardsList.length
      ) {
        this.data = this.getEntityUserDashboardsList
        this.total = this.data.length;
      }
      this.configureLoading = false
    },
    onEdit(row) {
      let urlName=''
        // if(this.checkUrlType() == 'private'){
        //   urlName ="entity-custom-dashboard-config2"
        // }
        // else{
          urlName = "entity-custom-dashboard-config"
        // }
      this.$router.push({
        name: urlName,
        params: {
          entityId: this.entity_id,
        },
        query: {
          dashboardId: row._id,
          name : this.$route.query.name
        },
      });
    },
    createNewDashboard(){
      this.$router.push({
          name: "entity-custom-dashboard-config",
          params: {
            entityId: this.entity_id,
          },
          query : {
            name : this.$route.query.name
          }
        });
    },
    goBack(){
        this.$router.push({
        path : '/entity'
      })
    },
    disableSwitch(value, index){
      if(!value){
        return false
      }
      else{
        let otherVisibleDashboards = this.data.filter((db, i) => db.main_dashboard_visible && i !== index)
        if(otherVisibleDashboards.length){
          return false
        }
        return true
      }
    },
    async updateDashboard(row){
      this.configureLoading = true
      this.loadingText = 'Updating Dashboard...'
      await this.$store.dispatch('customDashboard/updateDashboard',{id : row._id, params : {main_dashboard_visible : row.main_dashboard_visible}})
      this.getNecessaryInfo()
    },
    async onDelete(row){
      this.configureLoading = true
      this.loadingText = 'Deleting Dashboard...'
      await this.$store.dispatch("customDashboard/deleteDashboard", row._id);
      if (this.getDeleteDashboardStatus) {
        this.$notify({
          title: "Success",
          message: "Deleted successfully",
          type: "success",
        });
        this.getNecessaryInfo();
      } else {
        this.configureLoading = false;
        this.$notify({
          title: "Error",
          message: "Error while deleting dashboard",
          type: "error",
        });
      }
    }
  },
};
</script>
<style>
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #f562b3;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}

.time-stamp {
  font-size: 0.935em !important;
  color: #a1a0a3 !important;
}
.icon-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Optional: Adds an ellipsis (...) if text overflows */
}
</style>
